import FooterSectionComponent from '@wix/thunderbolt-elements/src/components/Sections/FooterSection/viewer/FooterSection';


const FooterSection = {
  component: FooterSectionComponent
};


export const components = {
  ['FooterSection']: FooterSection
};

